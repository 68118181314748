.play-button {
  background-image: url('../images/play-button.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 369px;
  height: 81px;

  @media only screen and (max-width: 389px) {
    width: 100%;
  }

  &:active {
    transform: translateY(4px);
  }
}

.swipe-btn {
  &:active {
    transform: translateY(4px);
  }
}

.swipe-yellow-btn {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 120px;
  padding: 2px;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: black;

  .inner {
    background: linear-gradient(180deg, #FDFFA4 0%, #BF8A00 100%), rgba(255, 255, 255, 0.1);
    border-radius: 120px;
    padding: 0.5em 1.5em;
  }

  .bottom-shine {
    position: absolute;
    bottom: -0.8em;
    right: 1em;
    width: 50%;
    height: 32px;
    background-image: url('../images/shine.svg');
    background-position: center;
    background-repeat: no-repeat;
    mix-blend-mode: lighten;
  }
}

.swipe-black-btn {
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 120px;
  padding: 0.5em 1.5em;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: white;
}