.swipe-answer {
  &.rounded-container {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 120px;
  }

  .swipe-ball {
    width: 20%;
    height: auto;
    box-sizing: border-box;
    margin-top: 5px;

    @media only screen and (min-width: 768px) {
      width: 10%;
    }
  }

  .inverted {
    transform: scaleX(-1);
  }

  & > img, .answer {
    width: 20%;
    height: auto;
  }
}
