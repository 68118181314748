.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}


.blur {
  &-dark {
    position: fixed;
    width: 336px;
    height: 336px;
    left: 250px;
    top: 56px;
    background: $blur-dark;
    mix-blend-mode: darken;
    filter: blur(100px);
  }

  &-light {
    position: fixed;
    width: 254px;
    height: 254px;
    left: -139px;
    top: -95px;
    background: $blur-light;
    filter: blur(100px);
  }
}

.rect {
  &-bottom {
    position: absolute;
    width: 100%;
    height: 70%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;

    @media only screen and (min-width: 600px) {
      background-size: 100% auto;
    }

    &-purple {
      background-image: url('../images/back-morado-juego.svg');
    }

    &-red {
      background-image: url('../images/incorrect-bg.svg');
    }

    &-green {
      background-image: url('../images/correct-bg.svg');
    }

    &-special {
      background-image: url('../images/special-bg.svg');
    }
  }

  &-scooped-corners {
    .corner {
      background: transparent;
      height: 40px;
      width: 40px;    
      position: absolute;
      opacity: 0.6;
    }

    .sw {
      left: 0px;
      bottom: 0px;
      border-radius: 0px 40px 0px 0px;
      border-top: 4px solid $green-gradient-one-badge;
      border-right: 4px solid $green-gradient-one-badge;

      &.red {
        border-color: $red-corner-one;
      }

      &.special {
        border-color: #BF8A00;
      }
    }

    .se {
      right: -2px;
      bottom: -2px;
      border-radius: 40px 0px 0px 0px;
      border-top: 4px solid $green-gradient-one-badge;
      border-left: 4px solid $green-gradient-one-badge;

      &.red {
        border-color: $red-corner-one;
      }

      &.special {
        border-color: $special-gradient-two;
      }
    }

    .nw {
      left: -2px;
      top: -2px;
      border-color: blue;
      border-radius: 0px 0px 40px 0px;
      border-bottom: 4px solid $swipe-yes-text-gradient-two;
      border-right: 4px solid $swipe-yes-text-gradient-two;

      &.red {
        border-color: $red-corner-one;
      }

      &.special {
        border-color: $special-gradient-two;
      }
    }

    .ne {
      right: -2px;
      top: -2px;
      border-radius: 0px 0px 0px 40px;
      border-bottom: 4px solid $grey-corner-one;
      border-left: 4px solid $grey-corner-one;

      &.red {
        border-color: $red-corner-two;
      }

      &.special {
        border-color: $special-gradient-one;
      }
    }

    .box-content {
      border-radius: 12px;
    }

    .top-shine {
      position: absolute;
      top: -20px;
      width: 100%;
      height: 32px;
      background-image: url('../images/shine.svg');
      background-position: center top;
      background-repeat: no-repeat;
      mix-blend-mode: lighten;
      rotate: 180deg;
    }

    .bottom-shine {
      position: absolute;
      bottom: -20px;
      width: 100%;
      height: 32px;
      background-image: url('../images/shine.svg');
      background-position: center bottom;
      background-repeat: no-repeat;
      mix-blend-mode: lighten;
    }
  }
}

.gradient-rounded-border-green {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  $border: 4px;
  background-clip: padding-box; /* !importan */
  border: solid $border transparent; /* !important */
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !important */
    border-radius: inherit; /* !important */
    background: linear-gradient(to right top, $gradient-border-green-one, $gradient-border-green-two, $gradient-border-gray);
  }
}

.gradient-rounded-border-red {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  $border: 4px;
  background-clip: padding-box; /* !importan */
  border: solid $border transparent; /* !important */
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !important */
    border-radius: inherit; /* !important */
    background: linear-gradient(to right top, $gradient-border-red-one, $gradient-border-red-two, $gradient-border-gray);
  }
}

.gradient-rounded-border-special {
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  $border: 4px;
  background-clip: padding-box; /* !importan */
  border: solid $border transparent; /* !important */
  border-radius: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !important */
    border-radius: inherit; /* !important */
    background: linear-gradient(to right top, $special-gradient-two, $special-gradient-one);
  }
}