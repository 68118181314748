.modal-content {
  &-bigger {
    height: 90vh;;
  }

  .darken-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(180deg, rgba(79, 40, 206, 0) 0%, #4C24CC 82.81%);
    border-radius: 0px 0px 12px 12px;
  }
}

.modal-body {
  &-scroll {
    overflow-y: scroll;
  }
}

.modal-gradient-purple-bg {
  background: linear-gradient(180deg, $purple-gradient-one 0%, $purple-gradient-two 100%);
}

.modal-transparent-border {
  outline: 4px solid rgba(255, 255, 255, 0.2);
  border: 0;
  border-radius: 12px;
}

.modal-instructions-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;

  .instructions-start {
    background: $instructions-start-container;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 12px;

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .instructions-swipe {
    margin-bottom: 70px; // equal or more than the darken-bottom height
  }
}
