.prize-modal {
  height: 60vh;
  background-image: url('../../images/red-rectangle-back.svg');
  background-size: cover;

  .prize-circles {
    mix-blend-mode: color-dodge;
    background-image: url('../../images/circles-back-intro.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .header {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;

    background-image: url('../../images/confeti.png');
    background-repeat: no-repeat;
    background-position: center;
    
  }

  .subheader {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .close-button {
    width: 26px;
    height: 26px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 48px;
    padding: 6px;

    .btn-close-prize {
      filter: invert(1) brightness(200%);
      opacity: 1;
    }
  }
}

.prize-modal-backdrop {
  opacity: 0.6 !important;
}