.leaderboard {
  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
    background: linear-gradient(180deg, rgba(56, 56, 56, 0) 0%, $leaderboard-graidient-one 56.77%),
      url('../../images/leaderboard-bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  &-bottom-bg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65vh;
    background-image: linear-gradient(180deg, $leaderboard-graidient-two 12.95%, $register-gradient-five 100%),
      linear-gradient(180deg, $leaderboard-graidient-three 0%, $leaderboard-graidient-four 100%);
  }

  .swipe-gol-header {
    font-weight: 800;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
    text-shadow: 0px 0px 24px rgba(253, 255, 164, 0.6);
  }

  .header {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.408px;
  }

  &-trophy {
    flex-basis: 10%;

    @media only screen and (min-width: 600px) {
      flex-basis: 3.5%;
    }
  }

  .player {
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.408px;

    &.other-player {  
      color: rgba(255, 255, 255, 0.5);
    }
  
    &.you {
      font-weight: 700;
      color: $leaderboard-you;
    }
    
    .points {
      position: relative;
      font-size: 50%;
      line-height: 0;
      vertical-align: baseline;
      top: -0.7em;
    }
  }

  .share-footer {
    * {
      flex-basis: 100%;
    }

    .share-with-friends {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      opacity: 0.5;
    }

    .social-media-icon {
      max-width: 40px;
      max-height: 40px;
      border-radius: 50%;
      background: transparent;
    }
  }
}