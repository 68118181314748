.register {
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../images/registro-back.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .header {
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.408px;
  }

  &-score {
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.408px;
  }

  &-prize-container {
    background-image: url('../../images/register-prize-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;

    .title {
      font-weight: 900;
      font-size: 24px;
      line-height: 24px;

      .bottom-shine {
        position: absolute;
        bottom: -0.8em;
        width: 100%;
        height: 32px;
        background-image: url('../../images/shine.svg');
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        mix-blend-mode: lighten;
      }
    }

    .description {
      font-weight: 900;
      font-size: 16px;
      line-height: 24px;
    }

    .transparent-border {
      opacity: 0.2;
      border: 1px solid black;
      height: 1px;
    }
  }

  &-inputs {
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
  }

  &-top-input {
    color: $register-gradient-four;

    ::placeholder {
      color: $register-gradient-four;
    }

    .icon {
      border-right: 0;
      background-color: white;
      border-radius: 12px 0 0 0;
    }

    .form-field {
      border-radius: 0 12px 0 0;
      border-left: 0;

    }
  }

  &-bottom-input {
    color: $register-gradient-four;

    ::placeholder {
      color: $register-gradient-four;
    }

    .icon {
      border-right: 0;
      background-color: white;
      border-radius: 0 0 0 12px;
    }

    .form-field {
      border-radius: 0 0 12px 0;
      border-left: 0;
    }
  }
}