.questions {
  &-field {
    position: absolute;
    width: 100%;
    height: 60%;
    left: 0px;
    right: 0px;
    top: 0px;
    background: url('../../images/back-cancha-juego.jpg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    
    @media only screen and (min-width: 600px) {
      height: 100%;
    }

    &.special {
      background: url('../../images/back-canchaespecial-juego.jpg');
      background-size: 100% auto;
      background-repeat: no-repeat;
    }
  }

  .bg-special-question {
    background-color: $bg-special-question;
  }

  .codere-logo {
    width: 140px;
    height: 33px;
  }

  .swipe-gol-header {
    font-weight: 800;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
    text-shadow: 0px 0px 24px rgba(253, 255, 164, 0.6);
  }

  .total-score {
    padding: 4px 8px;
    height: 28px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    font-size: 16px;
  }

  .question-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;

    &-incorrect {
      color: $question-text-incorrect;
    }

    &-correct {
      color: $question-text-correct;
    }
  }

  .added-score {
    padding: 4px 8px;
    height: 28px;
    min-width: 54px;
    font-size: 16px;

    &-correct {
      color: darken($swipe-yes-text-gradient-one, 20);
      background-color: $score-correct-bg;
      border-radius: 4px;
    }

    &-incorrect {
      color: darken($swipe-no-text-score, 20);
      background-color: $score-incorrect-bg;
      border-radius: 4px;
    }
  }

  .question-num-container {
    background: linear-gradient(180deg, rgba(192, 202, 206, 0.3) 0%, rgba(27, 221, 116, 0.3) 100%);
    border-radius: 20px 20px 0px 0px;
    padding: 10px 24px 0px 24px;
    color: $question-num;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    .question-current {
      color: $question-num-current;
    }

    &-incorrect {
      background: linear-gradient(180deg, rgba(206, 192, 196, 0.3) 0%, rgba(221, 27, 62, 0.3) 100%);
    }

    &-special {
      background:  linear-gradient(180deg, #FDFFA4 0%, #BF8A00 100%);
      color: black;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &-white-circles {
    position: absolute;
    width: 100%;
    height: 74%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    mix-blend-mode: luminosity;
    opacity: 0.8;
    background-image: url('../../images/circulos-blancos.png');
    background-size: 100% auto;
  }


  .badge-status {
    &-timer {
      background: linear-gradient(180deg, $purple-gradient-one 0%, $purple-gradient-two 100%);
      border-radius: 120px;

      &-special {
        background: linear-gradient(180deg, $special-gradient-one 0%, $special-gradient-two 100%),
          linear-gradient(180deg, $register-gradient-three 0%, $purple-gradient-two 100%);
        border-radius: 120px;
      }
    }

    &-incorrect {
      background: linear-gradient(180deg, $red-gradient-one-badge 0%, $red-gradient-two-badge 100%);
      border-radius: 120px;
    }

    &-correct {
      background: linear-gradient(180deg, $green-gradient-one-badge 0%, $green-gradient-two-badge 100%);
      border-radius: 120px;
    }
  }

  .special {
    &-question-description {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
    }

    &-double-points {
      font-weight: 800;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.02em;
    }
  }

  .question-answer {
    .swipe-ball {
      width: 20%;
      height: auto;

      @media only screen and (max-width: 389px) {
        width: 16%;
      }

      @media only screen and (min-width: 768px) {
        width: 10%;
      }

      @media only screen and (min-width: 1200px) {
        width: 5%;
      }
    }
  }

  .custom-spinner {
    height: 60px;
    width: 60px;

    @media only screen and (min-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }
}
