// Override bootstrap variables
@import './assets/stylesheets/variables.scss';

// import bootstrap
@import '~bootstrap/scss/bootstrap.scss';

// Custom styles
@import './assets/stylesheets/buttons.scss';
@import './assets/stylesheets/texts.scss';
@import './assets/stylesheets/utilities.scss';

// styles per view/component
@import './assets/stylesheets/components/play_view.scss';
@import './assets/stylesheets/components/swipe_answer.scss';
@import './assets/stylesheets/components/instructions_modal.scss';
@import './assets/stylesheets/components/questions_view.scss';
@import './assets/stylesheets/components/register_view.scss';
@import './assets/stylesheets/components/leaderboard_view.scss';
@import './assets/stylesheets/components/prize_modal.scss';

@font-face {
  font-family: 'Poppins';
  src: url('https://fonts.google.com/specimen/Poppins');
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
