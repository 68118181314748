.flag-and-ball {
  position: relative;
  top: 0;
  left: 0;

  img {
    width: 256px;
    height: 174px;
  }
}

.prize-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 170px;
  height: 34px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 12px 12px 0px 0px;
  transition: all 1s;

  &:hover {
    cursor: pointer;
  }

  &.close {
    bottom: 40%;
  }
}

.field {
  position: absolute;
  width: 100%;
  height: 60%;
  left: 0px;
  right: 0px;
  top: 0px;
  background-image: url('../../images/cancha-back-intro.jpg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  
  @media only screen and (min-width: 600px) {
    height: 100%;
  }
}

.red-rect {
  position: absolute;
  width: 100%;
  height: 56%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.98;
  background-image: url('../../images/shape-back-intro.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media only screen and (min-width: 600px) {
    // big screens
    background-size: 100% auto;
  }

  @media only screen and (max-height: 768px) {
    // really small screen
    background-size: 100% auto;
  }
}

.play-swipe-logo {
  width: 263px;

  @media only screen and (max-width: 389px) {
    width: 243px;
  }
}
