.text-gradient-special {
  background-image: linear-gradient(180deg, $special-gradient-one 0%, $special-gradient-two 83.33%);
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.swipe-gol-header {
  font-weight: 800;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0px 24px rgba(253, 255, 164, 0.6);
}

.ola-media-logo {
  width: 128px;
  height: 51px;
}

.qatar {
  width: 99px;
  height: 17.63px;
}

.sponsor {
  width: 139px;
  height: 22px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  opacity: 0.5;
}

.codere-logo {
  width: 184px;
  height: 44px;
}

.text-special-purple {
  color: $special-question-purple;
}

.thx-playing {
  color: white;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.8;
  text-transform: uppercase;
}
